import styled from 'styled-components'

export const Container = styled.div.attrs({
  className: 'authflow-country-select',
})`
  display: inline-block;
  width: 100%;
`

export const CountryFlag = styled.span`
  display: inline-block;
  width: 32px;
  height: 19px;
  margin-right: 10px;
  border: 1px solid #bebebe;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const CountryItemInner = styled.div.attrs({
  className: 'authflow-country-select-item',
})`
  display: flex;
  width: 100%;
`

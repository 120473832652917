import {
  Button,
  ContentContainer,
  Header,
  Icon,
  LoadingSpinner,
  TextInput,
} from '@components/Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '@components/App'
import {
  ButtonImgWrap,
  ButtonText,
  InputContainer,
  Label,
} from '@styles/generic.styles'
import {
  ContentContainerBottom,
  ContentContainerBottomLeft,
} from '@styles/layout.styles'

export const AccountHolderMockPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth } = store.AppState
  const { context } = store.ScenarioState
  const { currentPageIndex, currentScenarioId } = store.ScenarioState
  const { theme } = store.InterfaceState

  const { userDataPage: trans } = store.TranslationsState.translations

  const [accountHolderValue, setAccountHolderValue] = useState('')
  const [validationErrorHolder, setValidationErrorHolder] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)

  const handleProceed = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (currentScenarioId !== 'localTests') {
      setBtnLoading(true)
      const apiUrl = process.env.WEB_API_URL

      const output: {
        senderHolder?: string
      } = {
        senderHolder: accountHolderValue,
      }

      await axios.post(
        `${apiUrl}/micropayment/update-sender-holder-mock`,
        output,
        {
          withCredentials: true,
        }
      )

      setBtnLoading(false)
    }

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  function getDisabledState() {
    let output = true

    if (context.shouldMockSenderHolder) {
      if (accountHolderValue.length && !validationErrorHolder) {
        output = false
      }
    }

    return output
  }

  useEffect(() => {
    if (!context.shouldMockSenderHolder) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
  }, [])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <form onSubmit={(e) => handleProceed(e)}>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.header}
        </Header>
        <div>
          {context.shouldMockSenderHolder ? (
            <InputContainer>
              <Label>{trans?.inputLabel}</Label>
              <TextInput
                type="text"
                value={accountHolderValue}
                {...(trans.emailErrorLabel
                  ? { emailErrorLabel: trans.emailErrorLabel }
                  : {})}
                {...(trans.phoneErrorLabel
                  ? { phoneErrorLabel: trans.phoneErrorLabel }
                  : {})}
                {...(trans.requiredErrorLabel
                  ? { requiredErrorLabel: trans.requiredErrorLabel }
                  : {})}
                onChange={(value) => {
                  setAccountHolderValue(value)
                }}
                {...theme.textInput}
                onError={(e) => setValidationErrorHolder(e)}
                id="holder-input"
                autoComplete="name"
              />
            </InputContainer>
          ) : (
            <></>
          )}
        </div>
        <ContentContainerBottom>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            onClick={(e) => handleProceed(e)}
            disabled={getDisabledState()}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
            id="page-submit"
            type="submit"
          >
            {btnLoading ? (
              <LoadingSpinner
                width="16px"
                {...theme.button.loadingSpinner}
                padding="0 0"
                thickness={2}
              />
            ) : (
              <>
                <ButtonImgWrap>
                  <Icon size="18px" type="checkmark" />
                </ButtonImgWrap>
                <ButtonText>{trans.proceed}</ButtonText>
              </>
            )}
          </Button>
        </ContentContainerBottom>
      </form>
    </ContentContainer>
  )
})

import {
  Button,
  ContentContainer,
  CountrySelect,
  Header,
  Icon,
  LoadingSpinner,
  Symbol,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { actionReport } from '../methods/actionReport'
import { axiosInstance } from '../methods/axiosConfig'
import {
  ButtonImgWrap,
  ButtonText,
  CountryWrap,
  DocumentPicker,
  DocumentTypeWrap,
  Label,
  DocumentChecked,
} from '../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../styles/layout.styles'
import { onKeyDownHandler } from '@/methods/keyboardAccessibility'
import { mapCountries } from '@/methods/mapCountries'

export const SymbolTypes = [
  {
    value: 'ResidentPermit',
    label: 'Resident Permit',
  },
  {
    value: 'EUResidentPermit',
    label: 'EU Resident Permit',
  },
  {
    value: 'TravelTitle',
    label: 'Travel Title',
  },
  {
    value: 'DriverLicense',
    label: 'Driving license',
  },
  {
    value: 'Passport',
    label: 'Passport',
  },
  {
    value: 'IdentityCard',
    label: 'Identity card',
  },
]

export const IconTypes = [
  {
    value: 'residence-permit',
    label: 'ResidentPermit',
  },
  {
    value: 'residence-permit',
    label: 'EUResidentPermit',
  },
  {
    value: 'letter',
    label: 'TravelTitle',
  },
  {
    value: 'driving-license',
    label: 'DriverLicense',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
  {
    value: 'identity-card',
    label: 'IdentityCard',
  },
]

export const DocumentTypePage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { theme } = store.InterfaceState
  const { currentPageIndex, currentScenarioId, context } = store.ScenarioState
  const { documentTypePage: trans } = store.TranslationsState.translations

  const [docType, setDocType] = useState('')
  const [country, setCountry] = useState(null)
  const [apiCountries, setApiCountries] = useState([])
  const [countryDocTypes, setCountryDocTypes] = useState([])
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)

  const getDocTypes = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/document-types`,
        {
          withCredentials: true,
        }
      )

      res.data.documentTypes &&
        setApiCountries(mapCountries(res.data.documentTypes))
    } catch (e) {
      throw e
    }
  }

  const handleProceed = async () => {
    if (currentScenarioId !== 'localTests') {
      setBtnLoading(true)
      store.AppState.setDocType(docType)

      const apiUrl = process.env.WEB_API_URL

      await axiosInstance.post(
        `${apiUrl}/electronic-id/select-document-type`,
        {
          id: Number(docType),
        },
        {
          withCredentials: true,
        }
      )
      setBtnLoading(false)
    }

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handleDocumentType = (type: string, report?) => {
    setDocType('' + type)

    if (!report || (report && report !== false)) {
      actionReport({
        type: 'event.onboarding-web.document-selection.DOCUMENT_SELECTED',
        payload: {
          selectedDocument: '' + type,
        },
      })
    }
  }

  const handleCountry = (country, report?) => {
    if (country) {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === country.value
      )

      setCountry(countryApiObj)
      setCountryDocTypes(countryApiObj.types)
      setDocType('')

      if (!report || (report && report !== false)) {
        actionReport({
          type: 'event.onboarding-web.document-selection.COUNTRY_SELECTED',
          payload: {
            country: country.value,
          },
        })
      }
    } else {
      setCountry(null)
      setDocType('')
    }
  }

  useEffect(() => {
    if (apiCountries.length === 1) {
      handleCountry(apiCountries[0], false)
    }

    if (currentScenarioId === 'localTests') {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === 'PL'
      )

      setCountry(countryApiObj)
      setDocType('IdentityCard')
    }
  }, [apiCountries])

  useEffect(() => {
    if (countryDocTypes.length === 1) {
      handleDocumentType(countryDocTypes[0].id, false)
    }
  }, [countryDocTypes])

  const getDocLabel = (value) => {
    return trans[value]
  }

  const getDocIcon = (value) => {
    const typeIcon = IconTypes.find((docType) => docType.label === value)

    return typeIcon ? typeIcon.value : value
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    localStorage.setItem('permRetries', '0')
    if (!context.shouldSelectDocument) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
    getDocTypes()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>
      <CountryWrap>
        <span>{trans.selectCountry}</span>
        {mobileVersion ? (
          <>
            {' '}
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={true}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        ) : (
          <>
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={false}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        )}
      </CountryWrap>

      {country && countryDocTypes ? (
        <DocumentTypeWrap border={theme.container.border}>
          <Label data-testid="chooseDocument">{trans.chooseDocument}</Label>
          {countryDocTypes.map((type) => {
            return mobileVersion ? (
              <DocumentPicker
                onClick={() => handleDocumentType(type.id)}
                borderRadius={theme.container.borderRadius}
                borderWidth={theme.selectInput.borderWidth}
                currentBorder={theme.selectInput.borderColorFocus}
                currentBg={theme.selectInput.currentBg}
                currentColor={theme.selectInput.currentColor}
                className={'' + type.id === docType ? 'current' : ''}
                padding="8px"
                lineHeight={theme.selectInput.size || '46px'}
                marginBottom="10px"
                id={type.name}
                tabIndex={0}
                role="button"
                onKeyDown={(event: KeyboardEvent) =>
                  onKeyDownHandler(event, () => handleDocumentType(type.id))
                }
              >
                <Symbol
                  key={type.id}
                  {...theme.symbol}
                  type={getDocIcon(type.name)}
                  size={theme.symbol.size || '48px'}
                  color={
                    theme.symbol.current && '' + type.id === docType
                      ? theme.symbol.current
                      : theme.symbol.primary
                  }
                />
                <span>{getDocLabel(type.name)}</span>
                {theme.symbol.showChecked && '' + type.id === docType && (
                  <DocumentChecked>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.957 10.065C26.2892 10.4095 26.2793 10.9582 25.9347 11.2904L13.4907 23.2904C13.155 23.6142 12.6233 23.6142 12.2876 23.2905L6.06491 17.2905C5.72035 16.9582 5.71035 16.4096 6.04259 16.065C6.37482 15.7205 6.92347 15.7105 7.26803 16.0427L12.8891 21.4626L24.7315 10.0427C25.0761 9.71049 25.6247 9.72045 25.957 10.065Z"
                        fill={theme.symbol.current}
                      />
                    </svg>
                  </DocumentChecked>
                )}
              </DocumentPicker>
            ) : (
              <DocumentPicker
                onClick={() => handleDocumentType(type.id)}
                borderRadius={theme.container.borderRadius}
                currentBorder={theme.selectInput.borderColorFocus}
                currentBg={theme.selectInput.currentBg}
                currentColor={theme.selectInput.currentColor}
                className={'' + type.id === docType ? 'current' : ''}
                lineHeight={theme.selectInput.size || '64px'}
                id={type.name}
                tabIndex={0}
                role="button"
                onKeyDown={(event: KeyboardEvent) =>
                  onKeyDownHandler(event, () => handleDocumentType(type.id))
                }
              >
                <Symbol
                  key={type.id}
                  {...theme.symbol}
                  type={getDocIcon(type.name)}
                  size={theme.symbol.size || '64px'}
                  color={
                    theme.symbol.current && '' + type.id === docType
                      ? theme.symbol.current
                      : theme.symbol.primary
                  }
                />
                <span>{getDocLabel(type.name)}</span>
                {theme.symbol.showChecked && '' + type.id === docType && (
                  <DocumentChecked>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.957 10.065C26.2892 10.4095 26.2793 10.9582 25.9347 11.2904L13.4907 23.2904C13.155 23.6142 12.6233 23.6142 12.2876 23.2905L6.06491 17.2905C5.72035 16.9582 5.71035 16.4096 6.04259 16.065C6.37482 15.7205 6.92347 15.7105 7.26803 16.0427L12.8891 21.4626L24.7315 10.0427C25.0761 9.71049 25.6247 9.72045 25.957 10.065Z"
                        fill={theme.symbol.current}
                      />
                    </svg>
                  </DocumentChecked>
                )}
              </DocumentPicker>
            )
          })}
        </DocumentTypeWrap>
      ) : (
        <></>
      )}
      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() => handleProceed()}
          disabled={!(docType && docType.length)}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
          id="page-submit"
        >
          {btnLoading ? (
            <LoadingSpinner
              width="16px"
              {...theme.button.loadingSpinner}
              padding="0 0"
              thickness={2}
            />
          ) : (
            <>
              <ButtonImgWrap>
                <Icon size="18px" type="checkmark" />
              </ButtonImgWrap>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          )}
        </Button>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
